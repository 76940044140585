<template>
  <div class="list">
    <span
      v-if="is('mysql')"
    >
      <span class="mysql" />
      MySQL
    </span>
    <span
      v-if="is('vuejs')"
    >
      <span class="vuejs" />
      VueJs
    </span>
    <span
      v-if="is('vitest')"
    >
      <span class="vitest" />
      Vitest
    </span>
    <span
      v-if="is('symfony')"
    >
      <span class="symfony" />
      Symfony
    </span>
    <span
      v-if="is('codeigniter')"
    >
      <span class="codeigniter" />
      CodeIgniter
    </span>
  </div>
</template>

<script>
export default {
  name: "TechnologiesList",
  props: {
    options: Array,
    default: () => []
  },
  methods: {
    /**
     * @param {string} key
     * @returns {boolean}
     */
    is(key) {
      return this.options.includes(key);
    }
  }
}
</script>

<style scoped>
.list {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  font-weight: normal;
  justify-content: flex-end;
}

.list > span {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.symfony,
.codeigniter,
.mysql,
.vuejs,
.vitest {
  width: 20px;
  height: 20px;
  margin: 0 5px 0 20px;
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.symfony {
  background-image: url("../assets/logo-symfony.png");
}

.codeigniter {
  background-image: url("../assets/logo-codeigniter.png");
}

.mysql {
  background-image: url("../assets/logo-mysql.png");
}

.vuejs {
  background-image: url("../assets/logo-vuejs.png");
}

.vitest {
  background-image: url("../assets/logo-vitest.png");
}

</style>
