<template>
  <div class="container-description">
    <div class="separator" />
    <div class="title">
      <h2>
        <span class="bull">#</span>
        <font-awesome-icon class="icon" :icon="['fas', 'right-long']" />
        <a :href="url" target="_blank">{{ title }}</a>
      </h2>
      <languages-list
        class="languages"
        :options="languages"
      />
    </div>
    <div class="description">
      <div class="html-content" v-html="description" />
      <div class="source-code">
        <a :href="source" target="_blank"><span>[</span>source<span>]</span></a>
      </div>
      <technologies-list
        class="technologies"
        :options="technologies"
      />
    </div>
    <div class="separator" />
  </div>
</template>

<script>
import LanguagesList from "@/components/LanguagesList.vue";
import TechnologiesList from "@/components/TechnologiesList.vue";

export default {
  name: "ItemDescription",
  components: {TechnologiesList, LanguagesList},
  props: {
    title: {
      type: String,
      default: () => "",
    },
    description: {
      type: String,
      default: () => "",
    },
    languages: {
      type: Array,
      default: () => []
    },
    technologies: {
      type: Array,
      default: () => []
    },
    url: {
      type: String,
      default: () => "powerfullapp.ro"
    },
    source: {
      type: String,
      default: () => "powerfullapp.ro"
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
.container-description {
  width: 100%;
  margin: 15px 0 15px 0;

  .separator {
    height: 7px;
    border-left: solid 20px #d6d6d6;
    border-right: solid 20px #d6d6d6;
  }

  .title,
  .description {
    border-left: solid 7px #d6d6d6;
    border-right: solid 7px #d6d6d6;
    background-color: #f6f6f6;
  }

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 10px 0 10px;
    overflow: hidden;

    h2 {
      text-align: left;
      width: 100%;
      display: flex;
      flex-direction: row;

      .bull {
        font-size: 20px;
        font-weight: normal;
        color: #c3c3c3;
        margin-right: 10px;
      }

      .icon {
        margin-right: 5px;
        display: none;
      }

      a {
        text-decoration: none;
        color: #435160;
      }

      a:hover {
        text-decoration: underline;
      }
    }
  }

  .description {
    padding: 0 10px 10px 38px;
    display: grid;
    grid-template:
      "content content" auto
      "code technologies" 30px
      / 1fr auto;
  }

  .html-content {
    grid-area: content;
    margin-bottom: 10px;
  }

  .source-code {
    grid-area: code;
    display: flex;
    align-items: center;
    font-family: "Courier New", sans-serif;

    a {
      color: #435160;
      text-decoration: none;
      display: flex;
      padding: 0 3px 0 3px;
      margin-left: -5px;
    }

    a:hover {
      color: #fff;
      background-color: blue;
    }
  }

  .technologies {
    grid-area: technologies;
    display: flex;
    align-items: center;
  }

  &:hover .title,
  &:hover .description {
    animation: fadeBackground 2s;
    animation-fill-mode: forwards;
  }

  &:hover h2 {
    animation-name: slideTitle;
    animation-duration: 0.7s;

    .bull {
      display: none;
    }

    .icon {
      display: inline;
    }
  }

  &:hover .separator {
    animation: fadeBackgroundTopBottom 2s;
    animation-fill-mode: forwards;
  }

  @media (max-width: 600px) {
    .title {
      flex-direction: column;

      h2 {
        margin-bottom: 5px;
      }

      .languages {
        margin: 0 0 10px 20px;
        width: 100%;
      }
    }
  }

  @media (max-width: 350px) {
    .title {
      .languages {
        font-size: 0;
      }
    }
  }

}

@keyframes fadeBackground {
  from {
    background-color: transparent;
  }
  to {
    background-color: #fff;
    border-left: solid 7px blue;
    border-right: solid 7px blue;
  }
}

@keyframes fadeBackgroundTopBottom {
  from {
    background-color: transparent;
  }
  to {
    border-left: solid 20px blue;
    border-right: solid 20px blue;
  }
}

@keyframes slideTitle {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

</style>
