<template>
  <div class="list">
    <span
      v-if="is('php')"
    >
      <span class="php" />
      PHP
    </span>
    <span
      v-if="is('sql')"
    >
      <span class="sql" />
      SQL
    </span>
    <span
      v-if="is('vue')"
    >
      <span class="vue" />
      VueJs
    </span>
    <span
      v-if="is('js')"
    >
      <span class="js" />
      JavaScript
    </span>
    <span
      v-if="is('css')"
    >
      <span class="css" />
      CSS
    </span>
  </div>
</template>

<script>
export default {
	name: "LanguagesList",
	props: {
		options: Array,
		default: () => []
	},
	methods: {
    /**
     * @param {string} key
     * @returns {boolean}
     */
		is(key) {
      return this.options.includes(key);
    }
	}
}
</script>

<style scoped>
.list {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  font-weight: normal;
}

.list > span {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.php,
.sql,
.vue,
.js,
.css {
  display: inline-block;
  width: 7px;
  height: 7px;
  border-radius: 7px;
  margin: 0 5px 0 15px;
}

.php {
  background-color: #4f5b93;
}

.vue {
  background-color: #42b883;
}

.js {
  background-color: #ebd847;
}

.css {
  background-color: #663399;
}

.sql {
  background-color: #fda135;
}

</style>
